export const GET_ALL_USERS = () => `/data/users`; ////// will remove soon
export const GET_USER_DETAILS = (id) => `/data/user`; ////// will remove soon

// export const ROOT_URL = "http://192.168.0.57:8000";

export const BASE_URL = "https://dev.beclutch.club/api/";
// export const BASE_URL = "https://mobile.beclutch.club/api/";
export const urlUserLogin = BASE_URL + "auth/login";
export const urlRegistrationList = BASE_URL + "admin/get_registrations";
export const urlGetParticipant = BASE_URL + "admin/get_participants";
export const urlGetParticipantByLabel =
  BASE_URL + "admin/get_participants_label";
export const urlAvailableParticipant =
  BASE_URL + "admin/get_available_participant";
export const urlRegistrationDetailByGroup =
  BASE_URL + "admin/get_registration_group_detail";
export const urlRegistrationTransactionByGroup =
  BASE_URL + "admin/get_registration_transaction_group_detail";
export const urlRegistrationGroup = BASE_URL + "admin/get_registration_group";
export const urlRegistrationTransaction = BASE_URL + "admin/get_registration_transaction";
export const urlUpdateRegInfo = BASE_URL + "admin/update_reg_info";
export const urlUpdateCampRegInfo = BASE_URL + "admin/update_camp_reg_info";
export const urlDeleteRegInfo = BASE_URL + "admin/delete_reg_info";
export const urlGetDivisionAll = BASE_URL + "admin/get_division_all";
export const urlGetDivisionSchedule = BASE_URL + "admin/get_division_schedule";
export const urlGetDivisionTree = BASE_URL + "admin/get_division_tree";
export const urlGetDivisionFilters = BASE_URL + "admin/get_division_filter";
export const urlGetDivision = BASE_URL + "admin/get_division";
export const urlCreateDivisionFromReg =
  BASE_URL + "admin/create_division_from_registration";
export const urlCreateDivision = BASE_URL + "admin/create_division";
export const urlUpdateDivision = BASE_URL + "admin/update_division";
export const urlGetAvailableTeamList = BASE_URL + "admin/get_available_team";
export const urlGetTeamList = BASE_URL + "admin/get_team";
export const urlCreateTeam = BASE_URL + "admin/create_team";
export const urlUpdateTeam = BASE_URL + "admin/update_team";
export const urlDeleteDivision = BASE_URL + "admin/delete_division";
export const urlDeleteTeam = BASE_URL + "admin/delete_team";
export const urlCreateBeclutchTeam =
  BASE_URL + "admin/create_beclutch_team_4club";
export const urlAddRoster = BASE_URL + "admin/add_roster";
export const urlCreateRosterInvitation = BASE_URL + "create/invite_roster";
export const urlUpdateRosterInvitation =
  BASE_URL + "admin/update_roster_invitation";
export const urlDeleteRoster = BASE_URL + "admin/delete_roster";
export const urlSendMessageGroup = BASE_URL + "admin/send_message_group";
export const urlSendMessage = BASE_URL + "admin/send_message";
export const urlGetMessageList = BASE_URL + "admin/get_messages";
export const urlGetMessageDetailList = BASE_URL + "admin/get_messages_detail";
export const urlGetLocation = BASE_URL + "setting/get_locations";
export const urlAddLocation = BASE_URL + "setting/create_location_info";
export const urlUpdateLocation = BASE_URL + "setting/update_location_info";
export const urlGetScheduleBaseInfo = BASE_URL + "admin/get_schedule_base";
export const urlGetScheduleList = BASE_URL + "admin/get_schedule_list";
export const urlCreateSchedule = BASE_URL + "admin/create_schedule";
export const urlGetSetupInfo = BASE_URL + "admin/get_setup_info";
export const urlSetupSchedule = BASE_URL + "admin_schedule/setup_schedule";
export const urlUpdateStagedList = BASE_URL + "admin/update_stage_list";
export const urlDeleteStage = BASE_URL + "admin/delete_stage";
export const urlGetStagedGames = BASE_URL + "admin/get_staged_games";
export const urlCreateEvent =
  BASE_URL + "calendar/create_new_games_4club_league";
export const urlUpdateEvent = BASE_URL + "calendar/update_game_4club_league";
export const urlCancelEvent = BASE_URL + "calendar/cancel_game_4club_league";
export const urlDeleteEvent = BASE_URL + "calendar/delete_game_4club_league";
export const urlCreateClubEvent = BASE_URL + "admin/setup_one_schedule";
export const urlGetEventList = BASE_URL + "calendar/get_calendar_all_teams";
export const urlGetGameResult = BASE_URL + "calendar/get_game_score";
export const urlUpdateGameScore = BASE_URL + "calendar/update_game_score";
export const urlGetStandingList = BASE_URL + "admin/get_standing_list";
export const urlCreatePractice = BASE_URL + "calendar/create_new_practice";
export const urlCancelPractice = BASE_URL + "calendar/cancel_practice";
export const urlDeletePractice = BASE_URL + "calendar/remove_practice_game";

export const urlGetActivityList = BASE_URL + "auth/productions";
export const urlSaveRegistrationGeneral = BASE_URL + "admin/save_registration_general";
export const urlSaveRegistrationMain = BASE_URL + "admin/save_registration_main";
export const urlSaveRegistrationWaiver = BASE_URL + "admin/save_registration_waiver";
export const urlSaveHomePageInfo = BASE_URL + "admin/save_homepage_info";
export const urlSaveRegistrationGroup =
  BASE_URL + "admin/save_registration_group";
export const urlChangeRegistrationStatus =
  BASE_URL + "admin/update_registration_status";
export const urlGetRegistrationSettings =
  BASE_URL + "admin/get_registration_settings";

export const urlAddUIForm = BASE_URL + "admin/add_ui_form";
export const urlUpdateUIForm = BASE_URL + "admin/update_ui_form";
export const urlDeleteUIForm = BASE_URL + "admin/delete_ui_form";
export const urlGetUIForms = BASE_URL + "admin/get_ui_forms";

export const urlGetProfileInfo = "";
export const urlUpdateProfileInfo = "";
