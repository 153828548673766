import React from "react";
import { withRouter } from "react-router";
import { Empty, Table } from "antd";
import {
  showInfoAlert,
  showColumnFeature,
  showColumnStoreType,
  showColumnAction,
} from "./FormUiTableColumn";
import "./FormUiTable.css";
import QRGenerator from "components/QRCodeGenerator/qrGenerator";
import moment from "moment";
import { numberWithCommas } from "utils/GlobalFunctions";
function FormUiTable(props) {
  const { dataList, deleteData, updateData } = props;
  if (dataList === undefined) return <div />;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Hint Text",
      dataIndex: "hint_text"
    },
    {
      title: "Reg Column Name",
      dataIndex: "reg_column_name"
    },
    {
      title: "Group",
      dataIndex: "group_title"
    },
    {
      title: "Order",
      dataIndex: "order"
    },
    {
      title: "UI Type",
      render: (info) => {
        let type = "";
        if (info.ui_type == "1") {
          type = "Text"
        } else if (info.ui_type == "2") {
          type = "Number"
        } else if (info.ui_type == "3") {
          type = "Dropdown"
        } else if (info.ui_type == "4") {
          type = "Phone Number"
        } else if (info.ui_type == "5") {
          type = "Button"
        }
        return type;
      },
    },
    {
      title: "Dropdown List",
      render: (info) => {
        return info.dropdown_list.length > 20 ? info.dropdown_list.slice(0, 19) + "..." : info.dropdown_list;
      },
    },
    {
      title: "Required",
      render: (info) => {
        return info.is_required == "true" ? "Yes" : "No";
      },
    },
    {
      title: "Encrypt",
      render: (info) => {
        return info.is_encrypt == "true" ? "Yes" : "No";
      },
    },
    {
      title: "FilePicker",
      render: (info) => {
        return info.is_filepicker == "true" ? "Yes" : "No";
      },
    },
    {
      title: "Action",
      dataIndex: "operator",
      width: 155,
      fixed: "right",
      render: (op, info) => {
        return showColumnAction(info, deleteData, updateData);
      },
    },
  ];

  const dataSource = dataList.map((data, index) => {
    return { ...data, key: index };
  });
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      rowClassName={"club-table-row"}
      locale={{
        emptyText: (
          <div>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Registrations Found"
            ></Empty>
          </div>
        ),
      }}
      scroll={{ x: 900 }}
      pagination={{ pageSize: 10 }}
    />
  );
}

export default withRouter(FormUiTable);
