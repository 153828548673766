import React from 'react';
import { Tag, Popconfirm, Space, message } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';


export function showColumnAction(record, onDeleteRecord, onUpdateRecord) {
  return (
    <Space size="middle" direction={"vertical"}>
      <a
        onClick={() => {
          onUpdateRecord(record)
        }}
      >
        Edit
      </a>
      <a style={{ color: "var(--redColor)" }} onClick={async () => {
        var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this data?" });
        if (res == false) return;
        onDeleteRecord(record.id)
      }}>
        Delete
      </a>
    </Space>
  );

}

